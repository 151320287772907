<template>
  <div>
    <div class="myRobot myRobotChat2">
      <div style=" background: #2563eb; color: #ffffff;padding: 8px; position: fixed; top: 0; width: calc(100% + 2px); left: 0; z-index: 3;">
        <div style="font-size: 16px;">{{title}}</div>
        <div style="margin-top: 10px;font-size: 13px;">{{subtitle}}</div>
      </div>

      <div class="myRobotRight">
        <div class="myRobotChatTop" v-loading="loading_message">
          <div class="AiTitle">
            <div class="chat_list dog">
                <div class="chat_list_right">
                  <div class="list_right_dd"><img src="@a/img/bg30.jpg"/></div>
                  <div class="list_right_dt">
                    <div class="right_dt_r">Bot</div>
                    <div class="right_dt_l"></div>
                  </div>
                </div>
                <div class="chat_list_left">
                  <div class="list_left_txt">
                    <div class="left_txt_question">
                      <div class="txt_question_con" style="padding-bottom: 10px;">
                        {{ opening_remarks }}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div v-for="(item, index) in chatList">
            <div class="chat_list user">
              <div class="chat_list_right">
                <div class="list_right_dt">
                  <div class="right_dt_l">{{ item.create_time}}</div>
                  <div class="right_dt_r">You</div>
                </div>
                <div class="list_right_dd"><img :src="img_dog"/></div>
              </div>
              <div class="chat_list_left">
                <div class="list_left_txt">
                  <div class="left_txt_question">
                    <div class="txt_question_p">{{ item.user_questions }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat_list dog">
              <div class="chat_list_right">
                <div class="list_right_dd"><img :src="img_ai"/></div>
                <div class="list_right_dt">
                  <div class="right_dt_r">Bot</div>
                  <div class="right_dt_l">{{ item.id > max_history_msg_id ? item.answer_time : item.answer_time_str}}</div>
                </div>
              </div>
              <div class="chat_list_left">
                <div class="list_left_txt">
                  <div class="left_txt_question">
                    <div class="txt_question_con">
                      <markdown-it-vue-light class="txt_question_p" :content="item.machine_answer"/>
                      <div class="txt_question_icon">
                        <div class="question_icon_li notBlue " :class="{'active': item.is_positive_reviews == 1}" @click="like(item)">
                          <img src="@a/img/sv68on.svg"/>
                          <img src="@a/img/sv68.svg"/>
                        </div>
                        <div class="question_icon_li notBlue" :class="{'active': item.is_positive_reviews == -1}" @click="unlike(item)">
                          <img src="@a/img/sv69on.svg"/>
                          <img src="@a/img/sv69.svg"/>
                        </div>
                      </div>
                    </div>
                    <div class="txt_question_source" @click="toggleClass(item)" :class="{ 'active': item.isActive }" v-if="item.referenced_files.length">
                      <div class="question_source_title notBlue">查看来源<i class="el-icon-arrow-down"></i></div>
                      <div class="question_source_down">
                        <a :href="url.url" class="source_down_list" v-for="(url, url_index) in item.referenced_files"
                           target="_blank">{{ url.url }}_{{ url.score }}</a>
                      </div>
                    </div>
                    <template v-if="item.id > max_history_msg_id">
                      <el-form ref="form" label-width="50px" v-if="item.event_details.id && item.is_answer_over" style="background: #f2f4f7; padding: 20px; border-radius: 6px;">
                        <div style="font-size: 16px; margin-bottom: 25px; border-left: 4px solid #2563eb; color: #222; display: flex; align-items: center; padding-left: 10px; height: 20px;">{{item.event_details.form_name}}</div>
                        <el-form-item :label="field" v-for="(field, index) in item.event_details.form_field" :key="'field_'+index">
                          <el-input v-model="item.user_submission[field]" name="field" :readonly="item.is_event_submission==1"></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" v-if="!item.is_event_submission" @click="submitUserInfo(item)">提交信息</el-button>
                        </el-form-item>
                      </el-form>
                    </template>
                    <template v-else>
                      <el-form ref="form" label-width="50px" v-if="item.is_event_submission" style="background: #f2f4f7; padding: 20px; border-radius: 6px;">
                        <el-tag >{{item.event_details.form_name}}</el-tag>
                        <el-form-item :label="field" v-for="(field, index) in item.event_details.form_field" :key="'field_'+index">
                          <el-input v-model="item.user_submission[field]" name="field" :readonly="item.is_event_submission==1"></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" v-if="!item.is_event_submission" @click="submitUserInfo(item)">提交信息</el-button>
                        </el-form-item>
                      </el-form>
                    </template>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="myRobotChat_submit">
          <div class="chatDogHome_submit_input">
            <div class="submit_input_left">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 3}"
                  :placeholder="preset_questioning_options + prompt_placeholder"
                  v-model="input"
                  v-enter-prevent="send">
              </el-input>
              <div class="submit_input_btn">
                <div class="input_btn_click" @click="send" v-if="!showLoading"><img src="@a/img/bn29.png"/></div>
                <div class="la-ball-fall la-2x input_btn_loading" :class="{ 'is-active': showLoading }">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!is_remove_brand" class="myRobotChat_by">Powered by <a href="https://www.ai-dog.net/" target="_blank"><img src="@a/img/logo2.png"/>AIDOG</a></div>
      </div>
    </div>
  </div>
</template>


<script>
import {MessageBox, Message} from 'element-ui';
import {Loading} from 'element-ui';

import MarkdownItVueLight from 'markdown-it-vue/dist/markdown-it-vue-light.umd.min.js'
import 'markdown-it-vue/dist/markdown-it-vue-light.css'
import {api} from "@/api-js";
import config from "@/config";
import axios from "axios";



export default {
  name: "chatDog",
  data() {
    return {
      prompt_placeholder: ' (Shift + Enter = 换行)',
      showLoading: false,
      img_ai: require("@a/img/bg30.jpg"),
      img_dog: '',
      chatList: [],
      title: '',
      subtitle: '',
      input: '',
      conversation_id: 0,
      user_submission: {},
      robot_id: 0,
      loading_message: false,
      max_history_msg_id : 0,
      opening_remarks: '',
      preset_questioning_options: '',
      is_remove_brand: false,
      domain: '',
      is_answer_over: 0,
    };
  },

  components: {
    MarkdownItVueLight
  },
  directives: {
    'enter-prevent': {
      inserted: function (el, binding) {
        el.addEventListener('keydown', function (event) {
          if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
            // 执行绑定的函数
            binding.value();
          }
        });
      }
    }
  },
  methods: {
    async like(item) {
      const [e, r] = await api.chat.like(item.id);
      if(!e && r ){
        if(r.code){
          item.is_positive_reviews = 1;
        }
      }
    },
    async unlike(item) {
      const [e, r] = await api.chat.unlike(item.id);
      if(!e &&r ){
        if(r.code) {
          item.is_positive_reviews = -1;
        }
      }
    },
    async submitUserInfo(item){
      const [e, r] = await api.chat.userSubmitEvent({
        msg_id: item.id,
        user_submission: item.user_submission
      });
      if(!e && r ){
        if(r.code) {
          item.is_event_submission = 1;
          Message({
            message: '提交成功',
            type: 'success'
          });
        }
      }
    },

    async loadHistory(){
      this.loading_message = true;
      const [e, r] = await api.chat.getConversationById(this.conversation_id)
      this.loading_message = false;
      if(!e && r) {
        this.chatList = r.result.messages;
        this.chatList.forEach((item, index) => {
          if(item.id > this.max_history_msg_id){
            this.max_history_msg_id = item.id;
          }
        })
      }
    },

    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDateTime;
    },
    toggleClass(item) {
      item.isActive = !item.isActive;
    },

    async send() {
      // await this.start();
      let that = this;
      this.showLoading = true;
      let msg = that.input;
      that.input = '';

      if (!this.conversation_id || this.conversation_id === 'undefined' ) {
        const [chat_e, chat_r] = await api.chat.createConversation({
          robot_id: this.robot_id,
          query: msg,
        });
        if (!chat_e && chat_r) {
          this.conversation_id = chat_r.result.id;
          sessionStorage.setItem('chat_id', this.conversation_id)
        } else {
          return;
        }
      }

      const [msg_e, msg_r] = await api.chat.createMessage({
        conversation_id: this.conversation_id,
        user_questions: msg,
        robot_id: this.robot_id,
      });
      let msg_id = 0;
      if (!msg_e && msg_r) {
        if(msg_r.code === 1){
          msg_id = msg_r.result.id;
          this.chatList.push({
            create_time: this.getCurrentDateTime(),
            answer_time: this.getCurrentDateTime(),
            user_questions: msg,
            machine_answer: '',
            referenced_files: [],
            isActive: false,
            id: msg_r.result.id,
            is_positive_reviews: 0,
            user_submission: {},
            event_details: {},
            is_event_submission: 0,
          })
        }else{
          that.showLoading = false;
          return;
        }
      } else {
        that.showLoading = false;
        return;
      }


      let source = new EventSource(that.baseURL + `/api1/chat/chat?event_name=newChatMsg&msg=${msg}&robot_id=`
          + this.robot_id + '&msg_id=' + msg_id);


      //监听test事件
      source.addEventListener('newChatMsg', function (event) {
        if (event.data) {
          if (event.data === 'end') {
            that.showLoading = false;
            let len = that.chatList.length;
            that.chatList[len - 1].is_answer_over = 1;
            source.close()
          } else {
            let res = event.data
            res = JSON.parse(res)
            res = res.title

            let len = that.chatList.length;
            that.chatList[len - 1].machine_answer += res;
            that.chatList = JSON.parse(JSON.stringify(that.chatList))

          }
        }
        window.scrollTo(0, document.body.scrollHeight);
      });

      const [e, r] = await api.robotFiles.reference(this.robot_id, msg, msg_id)
      if (!e && r) {
        let len = that.chatList.length;
        that.chatList[len - 1].referenced_files = r.result.references
        if(r.result.event){
          that.chatList[len - 1].event_details = r.result.event
          for(let i in r.result.event.form_field){
            let field = r.result.event.form_field[i];
            this.chatList[len - 1].user_submission[field] = ''
          }

        }
      }
    }
  },
  async mounted() {
    const code  = this.$route.query.code
    const domain = this.$route.query.domain
    const [e, r] = await api.chat.getRobotByCode({code: code, domain:domain})
    if(!e && r){
        if(r.code === 1){
          this.robot_id = r.result.id
          this.is_remove_brand = r.result.is_remove_brand
          this.opening_remarks = r.result.opening_remarks
          this.preset_questioning_options = r.result.preset_questioning_options
          this.img_dog = r.result.consultation_icon ? r.result.consultation_icon : require("@a/img/bg30.jpg")
          this.title = r.result.title
          this.subtitle = r.result.subtitle

          let chat_id  = sessionStorage.getItem('chat_id') ? sessionStorage.getItem('chat_id') : 0
          if(chat_id){
            this.conversation_id = chat_id
            await this.loadHistory()
          }
          if(this.conversation_id){
            console.log('get chat history')
          }
        }
    }
  }
}
</script>


<style>
@import "chat.css";
</style>